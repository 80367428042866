<template>
  <cz-dialog
    :width="500"
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('customer.vendorSubmissions.exportSubmissions')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form class="pa-4" @submit.prevent="onExport" :disabled="loading">
        <cz-form-field required :label="$t('common.fromDate')">
          <validation-provider
            rules="required|date_format:dd/MM/yyyy"
            :name="$t('common.fromDate')"
            v-slot="{ errors }"
          >
            <cz-date-input
              v-model="form.fromDate"
              dense
              :error-messages="errors"
              clearable
            />
          </validation-provider>
        </cz-form-field>

        <cz-form-field required :label="$t('common.toDate')">
          <validation-provider
            rules="required|date_format:dd/MM/yyyy"
            :name="$t('common.toDate')"
            v-slot="{ errors }"
          >
            <cz-date-input
              v-model="form.toDate"
              dense
              :error-messages="errors"
              clearable
            />
          </validation-provider>
        </cz-form-field>

        <v-btn
          color="primary"
          type="submit"
          :disabled="invalid"
          class="mt-6"
          :loading="loading"
          >{{ $t('common.download') }}</v-btn
        >
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzDateInput
} from '@/components';
import { parse, isEqual, isBefore, isAfter } from 'date-fns';
export default {
  name: 'ExportSubmissionsDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzAutocomplete,
    CzDateInput
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  created() {
    if (this.filteredInvitationTypes?.length === 1) {
      this.form.role = this.filteredInvitationTypes[0].value;
      this.invitationRoleDisabled = false;
    }
  },
  data() {
    return {
      form: {
        fromDate: null,
        toDate: null
      }
    };
  },
  methods: {
    onExport() {
      const fromDate = parse(this.form.fromDate, 'dd/MM/yyyy', new Date());
      const toDate = parse(this.form.toDate, 'dd/MM/yyyy', new Date());
      this.$emit('on-export', {
        fromDate,
        toDate
      });
    }
  }
};
</script>

<style></style>
